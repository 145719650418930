<template>
  <b-card class="p-3">
    <client-link-branch
      @branch-linked="handleBranchLinked"
      @hide="$router.go(-1)"
    />
  </b-card>
</template>

<script>
import ClientLinkBranch from "./ClientLinkBranch";

export default {
  name: "BatchDetailMobile",

  components: {
    ClientLinkBranch
  },

  methods: {
    handleBranchLinked(addr) {
      this.$emit("branch-linked", addr);
    }
  }
};
</script>

<style lang="scss" scoped></style>
